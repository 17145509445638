import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [addedToCart, setAddedToCart] = useState(false); // State to manage feedback
    const { addToCart } = useContext(CartContext);

    useEffect(() => {
        // Fetch the product details from the Django API
        fetch(`/api/products/${id}/`)
            .then(response => response.json())
            .then(data => setProduct(data))
            .catch(error => console.error('Error fetching product:', error));
    }, [id]);

    const handleAddToCart = () => {
        addToCart(product);
        setAddedToCart(true); // Set feedback state to true
        setTimeout(() => setAddedToCart(false), 2000); // Reset feedback after 2 seconds
    };

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div className="product-detail">
            <img src={product.images[0]?.image} alt={product.name} />
            <h1>{product.name}</h1>
            <p>{product.description}</p>
            <p>${product.price}</p>
            <button onClick={handleAddToCart}>Add to Cart</button>
            {addedToCart && <p style={{ color: 'green' }}>Added to cart!</p>} {/* Feedback message */}
        </div>
    );
};

export default ProductDetail;
